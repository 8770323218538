.Leads {
    padding: 100px 32px;
}

.Leads__spinner-wrapper {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.filter-form {
    display: flex;
    align-items: center;
}

.select {
    min-width: 150px;
}