.arrow-after::after,
.arrow-before::before {
    content: url('./../../icons/push-notification-arrow.svg');
    display: flex;
    justify-content: center;
    margin: 0px auto;
}

/* Start AudienceTable Component */
.push-notification-header {
    margin: 5px 15px;
}
.push-notification-header::after {
    content: "";
    background-color: #3BB54A;
    width: 150px;
    height: 5px;
    display: inline-block;
    margin-left: 10px;
    border-radius: 25px;
}
.audience-table-container {
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 12.5527px;
    line-height: 17px;
    filter: drop-shadow(0px 6.27637px 12.5527px rgba(50, 50, 93, 0.102));
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.audience-table thead th
{
    background-color: #82888D;
    height: 26px;
}
.audience-table th,
.audience-table td {
    padding: 6px 15px !important;
    border: 1px solid #E6EBF1 !important;
}
.audience-table .audience-record {
    cursor: pointer;
}
.audience-table .audience-record.active td {
    background-color: #F6F9FC;
}
.audience-controller {
    display: flex;
    justify-content: space-between;
}
.audience-controller .aud-btn {
    background: #93A3CB;
    border: 1px solid #93A3CB;
    border-radius: 5px;
    color: #000000;
    font-size: 14px;
}
.audience-controller img {
    width: 20px;
    cursor: pointer;
}
.new-aud-btn-container {
    background-color: white;
    padding: 8px 10px;
}
.new-aud-btn-container .new-aud-btn {
    background: #C9C9C9;
    border: 1px solid #C9C9C9;
    border-radius: 5px;
    color: #000000;
    font-size: 14px;
}
@media (max-width: 991px) {
    .push-notification-header::after {
        display: none;
    }
}
/* End AudienceTable Component */


/* Start PushNotificationForm Component */
.push-notification-form .form-group {
    background-color: white;
    padding: 10px 15px;
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.push-notification-form .inline-form-group {
    background-color: white;
    padding: 5px 15px;
}
.push-notification-form .switch-container {
    border-radius: 8px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.push-notification-form .switch-container hr {
    background-color: #C4C4C4;
}
.push-notification-form .label {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    line-height: 17px;
}
.push-notification-form .notification-colorful {
    background: #D3DCE7 !important;
    border: 0.640319px solid #979797;
}
.push-notification-form .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background-color: #495572;
}
.push-notification-form .ant-select-single.ant-select-show-arrow .ant-select-selection-item, 
.push-notification-form .ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder,
.push-notification-form .ant-select {
    font-family: Inter;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    color: #FFFFFF;
    text-align: center;
}
.push-notification-form .ant-form-item {
    margin-bottom: 0px;
}
.push-notification-form .notification-details {
    margin-top: 8px;
}
.push-notification-form .custom-border {
    border-right: 1px solid #979797;
}
.push-notification-form .notification-details p {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 15px;
    margin-left: 10px;
    margin-bottom: 5px;
}
.push-notification-form .notification-details p span {
    color: #979797;
}
.push-notification-form .push-notification-button {
    background: #00244D;
    border: 1px solid #00244D;
    border-radius: 5px;
    margin: 25px auto 0px;
    padding: 5px 40px;
    height: 40px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 28px;
}
.push-notification-form .alert-notification-msg {
    margin-top: 20px;
}
@media (max-width: 576px) {
    .push-notification-form .message-type-container {
        margin-left: 0px;
    }
}
/* End PushNotificationForm Component */


/* Start NotificationPreview Component */
.notification-preview {
    margin-top: 20px;
}
.notification-preview .ant-tabs-top > .ant-tabs-nav::before, 
.notification-preview .ant-tabs-bottom > .ant-tabs-nav::before, 
.notification-preview .ant-tabs-top > div > .ant-tabs-nav::before, 
.notification-preview .ant-tabs-bottom > div > .ant-tabs-nav::before {
    border: none;
}
.notification-preview .ant-tabs-tab {
    padding: 0px;
}
.notification-preview .ant-tabs-tab-btn {
    border-radius: 100px;
    width: 110px;
    height: 28px;
    line-height: 28px;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    text-align: center;
    letter-spacing: -0.078px;
    color: #00244D;
}
.notification-preview .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #FFFFFF;
    background: #333333;
}
.notification-preview .ant-tabs-ink-bar {
    display: none;
}
.notification-preview .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, 
.notification-preview .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list {
    border: 2px solid #333333;
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #E9EEF5 100%);
    border-radius: 35px;
    padding: 3px;
}
.notification-preview .screen-preview-img {
    height: 100%;
}
.notification-preview .ipad-preview-img {
    width: 100%;
}
@media (max-width: 576px) {
    .notification-preview .ant-tabs-tab-btn {        
        width: 70px;
        height: 20px;
        line-height: 20px;
        font-size: 10px;
    }
}
@media (max-width: 767px) {
    .notification-preview .screen-preview-img {
        width: 100%;
    }
}
/* End NotificationPreview Component */


/* Start AudienceForm Component */
.audience-modal .ant-modal-body {
    background-color: #F6F9FC;
    padding-bottom: 5px;
}
.audience-form {
    font-family: Nunito;
}
.audience-form .label {
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
}
.audience-form .ant-form-item-label {
    padding-bottom: 0px;
}
.audience-form .ant-form-item {
    margin-bottom: 10px;
}
.audience-form .platform-option.active {
    background-color: #0097C2;
}
.audience-form .platform-option.active p{
    color: white;
}
/* .audience-form .platform-option.active .ant-radio-inner {
    background: transparent;
    border: 1px solid transparent;
}
.audience-form .platform-option.active input[type='radio']:focus {
    outline: none !important;
} */
.audience-form .platform-option {
    background-color: white;
    width: 125px;
    min-height: 120px;
    margin-bottom: 10px;
    margin-right: 8px;
    display: inline-flex;
    justify-content: center;
    border-radius: 5px;
}
.audience-form .platform-option .android-platform {
    height: 60px;
    width: 70px;
}
.audience-form .platform-container {
    text-align: center;
    margin-top: 20px;
}
.audience-form .platform-option p {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
}
.audience-form .platform-option .ant-radio {
    position: absolute;
    right: 10px;
    top: 8px;
}
.audience-form .platform-option .ant-radio-inner {
    background: radial-gradient(50% 50% at 50% 50%, #FFFFFF 0%, #E9EEF5 100%);
    border: 1px solid #D3DCE7;
    width: 18px;
    height: 18px;
}
.audience-form .platform-option .ant-radio-inner::after {
    width: 10px;
    height: 10px;
    background-color: #50B25A;
}
.audience-form .platform-option.active .ant-radio-inner {
    background: radial-gradient(50% 50% at 50% 50%, transparent 0%, transparent 100%);
    border: 1px solid transparent;
}
.audience-form .platform-option.active .ant-radio-input:focus + .ant-radio-inner {
    box-shadow: none;
}
.audience-form .platform-option .ant-radio-inner::after {
transition-duration: 0.2s;
}

.audience-form .platform-option.active .ant-radio-inner::after {
    content: url('./../../icons/green-check.svg');
    background-color: transparent !important;
    transform: scale(1.2);
    top: 0px;
    left: 0px;
}
.audience-form .create-audience-btn {
    background: #00244D;
    border: 1px solid #00244D;
    border-radius: 5px;
    margin: 25px 5px 0px;
    padding: 5px 40px;
    height: 40px;
    font-family: Inter;
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    color: #E6EBF1;
    float: right;
}
.audience-form .cancel-audience-btn {
    background: #E2E9F0;
    border: 1px solid #E2E9F0;
    color: #1C335B;
}

.audience-form .query-label span{
    margin: 0 16px 0 0;
    display: inline-block;
}

.audience-form .query-textarea{
    resize: none;
}

/* End AudienceForm Component */