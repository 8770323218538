.OnboardingCard {
    position: fixed;
    bottom: 50px;
    left: 50px;
    background: white;
    width: 360px;
    z-index: 200;
}

.OnboardingCard__img {
    width: 100%;
    object-fit: cover;
}

.OnboardingCard__body {
    padding: 16px;
}

.OnboardingCard__title {
    font-size: 22px;
    text-align: center;
    margin-bottom: 16px;
}

.OnboardingCard__desc {
    text-align: center;
    color: rgb(149, 146, 146);
    margin-bottom: 16px;
}

.OnboardingCard__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
}