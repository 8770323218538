.stepper .ant-steps-item-content,
.stepper .ant-steps-item-icon {
    display: block;
}

.stepper .ant-steps-item-container {
    cursor: pointer;
}

.stepper .ant-steps-item-process>.ant-steps-item-container>.ant-steps-item-icon {
    background-color: #285CC1;
    border: 1px solid #285CC1;
}

.stepper .ant-steps-item-tail:after {
    background: #DBE8F4 !important;
    height: 6px;
    display: none;
}

.stepper .anticon {
    background-color: #285CC1;
    border: 1px solid #285CC1;
    color: #fff;
    line-height: 56px;
    width: 51px;
    height: 51px;
    box-shadow: none;
    border-radius: 50%;
}

.stepper .ant-steps-item-finish>.ant-steps-item-container>.ant-steps-item-tail::after {
    background-color: #3FD9A4 !important;
}

.stepper .ant-steps-item-tail {
    top: 20px;
    margin-left: 64px;
    width: 100%;
}

.stepper .ant-steps-item-icon {
    width: 50px;
    height: 50px;
    line-height: 50px;
    border: 1px solid #DBE8F4;
    margin-left: 38px;
}

.stepper .ant-steps-item-finish .ant-steps-item-icon {
    border: 1px solid #3FD9A4;
    top: 0px;
}

.stepper .ant-steps-item-finish .ant-steps-icon {
    top: -1px;
    left: -1px;
}

.stepper .ant-steps-finish-icon {
    background-color: #3FD9A4;
    border-color: #3FD9A4;
}

.stepper .ant-steps-item-wait .ant-steps-item-icon>.ant-steps-icon {
    font-family: Nunito;
    font-size: 18px;
    color: #00203A;
    top: -1px;
    left: -1px;
}

.stepper .ant-steps-item-title {
    line-height: 20px;
}

.stepper-body {
    padding: 35px 0px 10px;
}

.stepper-footer {
    margin-top: 25px;
    display: flex;
    justify-content: flex-end;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
}

.stepper-footer .stepper-btn {
    height: 40px;
    margin-left: 8px;
}

.stepper-footer .next-btn {
    width: 132px;
    line-height: 150%;
    border: 1px solid #285CC1;
    background-color: #285CC1;
    color: #fff;
    border-radius: 4px;
}

.stepper-footer .previous-btn {
    border: 2px solid #285CC1;
    color: #285CC1;
    border-radius: 8px;
}

@media (max-width: 767px) {
    .stepper .ant-steps-item-icon {
        margin-left: 0px;
    }
}