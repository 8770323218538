/* 
    override some ant styles
*/

.boarding-card{
    padding: 16px;
    border: 1px solid #DBE8F4;
    background-color: #fff;
    transition: background-color 300ms ease;
}

.boarding-card.active{
    box-shadow: 5px 5px 15px rgb(0, 0, 0, 0.1);
}

.boarding-card.hoverable:hover{
    background-color: hsl(0deg, 0%, 98%);
    cursor: pointer;
}

.boarding-card .ant-card-body{
    padding: 0;
}

/* 
    boarding styles
*/

.boarding-page{
    min-height: 100vh;
}

.boarding-bg{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../../assets/images/full-page-background.png") no-repeat center / cover;
    opacity: 0.6;
}

.top-error{
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -100%);
}

.steps-container .steps{
    margin: 0 0 50px;
}

.steps .ant-steps-item-content{
    width: auto;
    text-align: initial;
    margin: 0 0 0 42px;
}

.steps .ant-steps-item-title{
    transform: translateX(-30%);
    margin: 0;
}

.boarding-wrapper{
    display: flex;
    align-items: flex-start;
    /* max-width: 1200px; */
    max-width: 90%;
    padding: 50px 0;
    margin: 0 auto;
    position: relative;
    z-index: 2;
}

.boarding-content{
    flex-grow: 1;
    margin: 0 24px 0 0;
}

/* aside component styles */

.aside-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 25%;
    max-width: 360px;
    margin: 0 auto;
}

.aside-title{
    font-size: 16px;
    margin: 14px 0 18px;
    color: #00203A;
}

.aside-description{
    color: #315B83;
    margin-bottom: 40px;
}

.aside-img{
    margin: 30px 0 30px;
}

.bars{
    display: flex;
    justify-content: center;
    margin: 30px 0 0;
}

.bar{
    display: block;
    width: 9px;
    height: 2px;
    background-color: #DBE8F4;
    margin: 0 4px 0 0;
}

.bars .bar:last-child{
    margin: 0;
}

.bars .bar.active{
    background-color: #0097C2;
}

/* industry component styles */

.industry-options-wrapper{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 18px;
    margin: 0 0 50px;
}

.industry-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 16px;
    text-align: center;
}

.industry-item .img{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 160px;
    height: 80px;
    margin: 0 0 16px;
    position: relative;
}

.industry-item .img-bg{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.13;
}

.industry-item .title{
    color: #00203A;
    margin: 0 0 2px;
    font-size: 16px;
    display: block;
}

.industry-item .description{
    color: #315B83;
}

/* steps footer styles */

.steps-footer{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 50px;
}

.steps-footer .txt{
    color: #315B83;
    font-size: 12px;
}

.steps-footer button{
    width: 170px;
    height: 48px;
    background-color: #3FD9A4;
    color: #fff;
}

.steps-footer button:hover,
.steps-footer button:focus{
    background-color: #3FD9A4;
    color: #fff;    
}

/* data source wrapper */

.data-source-wrapper{
    color: #00203A;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 14px;
    align-items: start;
    margin: 0 0 30px;
    position: relative;   
}

.source-item{
    display: flex;
    align-items: center;
    margin: 0 0 18px;
    border-radius: 6px;
    cursor: pointer;
}

.source-item .img{
    margin: 0 18px 0 0;
}

.data-source-form{
    padding: 16px 16px 48px;
    background-color: #fff;
}

.form-group{
    margin: 0 0 16px;
}

.form-group label{
    font-size: 16px;
    color: #00203A;
    margin: 0 0 8px;
    display: block;
}

.form-group input{
    font-size: 16px;
}

.data-source-form .form-group:last-child{
    margin: 0;
}

/* applications styles */

.app-options-wrapper{
    margin: 0 0 50px;
}

.app-options-wrapper .select-all{
    margin: 0 0 50px;
}

.app-list{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 16px;
    position: relative;
}

.app-list .app-item .ant-card-body{    
    display: flex;
    border-radius: 6px;
    padding: 1px;
}

.app-item.boarding-card{
    border: none;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.app-item .app-logo{
    margin: 0 6px 0 0;
}
.app-item .app-logo img {
    width: 30px;
}
.app-item .app-description{
    align-self: center;
    display: block;
    margin: 0 14px 0 0;
    color: #00203A;
}

.app-description .ant-typography{
    display: block;
}

.select-app{
    margin: 0 0 0 auto;
}

/* complete page styles */

.complete-wrapper{
    margin: 0 0 40px;
}

.complete-wrapper .ant-card-body{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 415px;
    border-radius: 6px;
}

.complete-wrapper .img{
    margin: 0 0 30px;
}

.complete-wrapper .desc{
    color: #00203A;
    font-size: 16px;
}

.complete-wrapper .desc span{
    font-weight: 800;
    color: #0097c2;
}

.boarding-spinner{
    margin: 0 0 50px;
}