.profile-wrapper{
    padding: 80px 32px;
}

.profile-wrapper h2{
    margin: 0 0 40px;
}

.profile-header{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    right: 50px;
    top: 20px;
    /* margin: 0 0 16px;*/
}

.user-avatar{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F4F7;
    border-radius: 50%;
    font-size: 22px;
}

.user-avatar span{
    line-height: 0;
    font-weight: 600;
    color: rgb(0, 0, 0, 0.6);
}

.user-avatar.large{
    width: 100px;
    height: 100px;
    font-size: 36px;
}

.avatar-dropdown.ant-dropdown-menu{
    min-width: 150px;
}

.profile-header > div{
    margin: 0 8px;
}

.profile-header .notification-wrapper{
    width: 48px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #F3F4F7;
    border-radius: 50%;
}

.profile-content{
    background: hsla(0, 0%, 100%, 0.5);
    border: 1px solid #EBEDF1;
    border-radius: 12px;
    padding: 24px;
}

.avatar-wrapper{
    display: flex;
    align-items: center;
    margin: 0 0 24px;
}

.avatar-wrapper .avatar-action{
    margin: 0 16px;
    color: hsla(212, 100%, 15%, 1);
}

.user-info-form{
    display: flex;
    flex-wrap: wrap;
    max-width: 800px;
    justify-content: space-between;
}

.user-info-form .ant-form-item{
    width: 48%;
    display: flex;
    flex-direction: column;
}

.user-info-form .ant-form-item-label{
    text-align: left;
}

.user-info-form .ant-form-item-label label{
    color: #7D8A9A;
}

.user-info-form input{
    border-radius: 8px;
    border: 1px solid #EBEDF1;
    background: #fff;
    height: 50px;
}

.user-info-form .change-password{
    color: #0097C2;
    font-weight: 500;
}

.user-info-wrapper .divider{
    height: 1px;
    background: hsla(0, 0%, 59%, 1);
}

.company-information{
    margin: 24px 0 0;
}

.user-info-wrapper .toggle-btns{
    display: flex;
    justify-content: center;
    margin: 48px 0 0;
}

.user-info-wrapper .user-info-btn{
    background: #00244D;
    min-width: 90px;
    height: 32px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    color: #fff;
    cursor: pointer;
}

.user-info-wrapper .cancel-edit.user-info-btn{
    color: hsla(212, 100%, 15%, 1);
    background: #fff;
    margin: 0 90px 0 0;
}
