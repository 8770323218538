.full-page-spinner,
.full-page-error{
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-page{
    min-height: 100vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.login-page .side-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
}

.login-page .right-side{
    background: url("../images/blue-bg.jpg") no-repeat center / cover;
}

.left-side{
    background: url("../images/left-login-wave.svg") no-repeat 0% 0%,
                url("../images/right-login-wave.svg") no-repeat 100% 100%,
                #EFF4F8;
}

.left-side .title{
    color: #00203A;
    font-size: 22px;
    font-weight: 400;
    margin: 0 0 30px;
}

.login-form{
    display: flex;
    flex-direction: column;
    width: 400px;
    max-width: 100%;
}

.login-form .error-msg{
    color: #ff4d4f;
    font-size: 16px;
    margin: 0 0 16px;
    text-align: center;
}

.login-form-group{
    display: flex;
    flex-direction: column;
    margin: 0 0 18px;
}

.login-form-group label{
    color: #315B83;
    font-size: 16px;
    margin: 0 0 10px;
}

.login-form-group input{
    font-size: 16px;
    color: #315B83;
    background-color: #fff;
    border: 1px solid #DBE8F4;
    border-radius: 6px;
    padding: 12px 16px;
}

.login-form-group input:focus, input:active{
    outline: none;
    box-shadow: 0 0 1px 2px #0097C2;
}

.login-form .submit-btn{
    height: 48px;
    color: #fff;
    background-color: #0097C2;
    border-radius: 6px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.submit-btn .spinner i{
    background: #fff;
}