.ant-layout-sider {
    background: #294668 !important;
}

.side-menu .menu-item {
    margin: 0px !important;
    border-radius: 0 !important;
}

li.ant-menu-item.menu-item {
    margin: 0 !important;
}

.side-menu{
    background-color: #294668;
}
.side-menu .logo img {
    margin: 20px;
    max-width: 100%;
}
.side-menu .logo-trigger img {
    margin: 10px 0px;
    padding: 10px;
}
.side-menu .menu {
    background-color: #294668;
}
.side-menu .menu-item {
    border-bottom: 2px solid #00244D;
    margin: 0px !important;
}
.side-menu .ant-menu-dark .ant-menu-inline.ant-menu-sub {
    background-color: #00244D;
}
.side-menu .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected
{
    background-color: #000;
}
.side-menu .sub-menu-item {
    padding-left: 35px !important;
    margin: 0px !important;
}
.trigger {
    margin-left: -12px;
    margin-top: 18px;
    cursor: pointer;
    position: absolute;
}  

.site-layout .navbar {
    background-color: transparent;
    padding: 0px;
    position: absolute;
}
#master .ant-layout {
    background: none !important;
}
.site-layout .ant-layout-header {
    height: 45px;
}
.site-layout .active-app {
    height: 100%;
    justify-content: center;
    margin: 10px 50px;
    background-color: rgba(196, 196, 196, 0.46);
    border-radius: 25px;
    padding: 0px 20px;
}
.active-app .app-icon {
    height: 25px;
}
.active-app .ant-dropdown-link {
    font-size: 16px;
    font-weight: bold;
    color: #00244D;
    cursor: pointer;
}

@media (max-width: 767px) {
    #master {
        min-width: 500px;
    }
    .site-layout .active-app {
        margin: 10px 20px;
        padding: 0px 15px;
    }
    .ant-layout-header {
        line-height: normal;
    }
}
