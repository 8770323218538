/* Start Header Component Style */
.header {
    min-height: 25vh;
    padding: 20px 80px 0px;
}
.header-container {
    display: flex;
    justify-content: space-between;
}
.header .header-content {
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.header .header-content .title {
    width: 80%;
    font-size: 28px;
    line-height: 30px;
    margin-bottom: 0px;
}
.header .header-content p {
    line-height: 21px;
}
.header .header-img {
    padding-bottom: 40px;
}
.header .header-button {
    padding: 0px 35px;
    background: linear-gradient(90deg, #4659C1 -62.8%, #1E3D60 -62.7%);
}

@media (max-width: 991px) {
    .header-container img {
        display: none;
    }
    .header {
        padding: 80px 20px 30px;
    }
}
/* End Header Component Style */


/* Start ConfigOverviewCard Component Style */
.overview-card-container {
    width: 100%;
    margin-top: 25px;
}
.overview-card-container:last-child {
    margin-bottom: 40px;
}
.config-overview-card {
    border: none;
    border-radius: 10px;
}
.config-overview-card .card-title {
    color: white;
    font-family: Montserrat;
    font-weight: 600;
}
.config-overview-card .card-desc {
    color: rgba(209, 224, 251, 1);
    font-family: Montserrat;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
}

@media (max-width: 991px) {
    .overview-card-container {
        padding-left: 55px;
    }
}
/* End ConfigOverviewCard Component Style */


/* Start ConfigOverviewSlider Component Style */
.overview-slider-container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0px 0px 50px;
    margin-top: -25px;
}
.config-overview-slider {
    width: 70vw;
    padding: 20px 40px;
    background-color: #F3FBFE;
    border-radius: 10px;
    font-family: Inter;
}
.config-overview-slider .slider-title {
    font-size: 25px;
    align-items: center;
}
.config-overview-slider .slider-image {
    width: 100%;
}
.config-overview-slider .slider-dots li button {
    background: #8D9A9F;
    height: 4px;
    width: 45x;
    margin-left: 3px;
}
.config-overview-slider .slider-dots li.slick-active button {
    background: #0097C2;
}

@media (max-width: 991px) {
    .config-overview-slider {
        width: 60vw;
        padding: 15px 10px;
    }
    .config-overview-slider .slider-title {
        font-size: 18px;
    }
}
@media (max-width: 576px) {
    .config-overview-slider .slider-title {
        font-size: 16px;
        text-align: center;
        display: block;
    }
}
/* End ConfigOverviewSlider Component Style */