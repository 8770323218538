#app {
  /* background-image: url("./../images/list-app-background.svg"); */
  /* background-size: cover; */
  background-color: #f5f7f9;
  height: 100%;
  width: 100%;
  min-height: 100vh;
  position: absolute;
  padding: 20px;
  overflow: hidden;
}

.profile-header {
  position: fixed;
  z-index: 10000;
}

.all-apps {
  max-height: 90vh;
  overflow: auto;
  -webkit-transform: translate3d(0, 0, 0);
}

.all-apps::-webkit-scrollbar {
  width: 5px;
}

.all-apps::-webkit-scrollbar-thumb {
  background-color: #00244d;
}

@media (max-width: 991px) {
  .all-apps {
    overflow-x: hidden;
  }
}

/* Start Header Component */
.apps-header h1 {
  font-family: "Nunito";
  font-size: 30px;
}

.apps-header p {
  font-family: "Montserrat";
  font-size: 18px;
  font-weight: 600;
}

.new-app-link {
  background-color: #5372e7;
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  padding: 1rem 1.4rem;
  display: inline-block;
}
/* End Header Component */

/* Start Application Component */
.app-card {
  background: linear-gradient(0deg, #92a7c0 0%, #eafaff 100%);
  border: none;
  border-radius: 20px;
  padding: 1rem;
  height: 100%;
  /* min-height: 280px; */
  position: relative;
}

.app-card .app-icon {
  width: 50px;
}

.app-card .app-name {
  font-family: Nunito;
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 30px;
  /* text-align: center; */
  color: #666c84;
  width: 90%;
  /* margin-top: 10px; */
}

.app-card .app-status {
  text-align: center;
  color: #666;
  margin-top: -10px;
  margin-bottom: 10px;
}

.app-card .app-stat {
  width: 100%;
  padding: 20px 0;
  justify-content: space-between;
}

.app-card .app-stat h6,
.app-card .app-stat p {
  font-family: Inter;
  font-style: normal;
}

.app-card .app-stat h6 {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: rgba(102, 108, 132, 0.5);
}

.app-card .app-stat p {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #666c84;
}

.app-card .app-connection {
  width: 100%;
  padding: 0px 20px;
  justify-content: space-between;
}

.app-card .app-connection h6,
.app-card .app-connection p {
  font-family: Inter;
  font-style: normal;
  color: #00244d;
}

.app-card .app-connection h6 {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 0px;
}

.app-card .app-connection p {
  /* text-align: center; */
  margin-top: 0px;
}

.app-card .app-os {
  width: 100%;
  justify-content: end;
}

.app-card .icon-container {
  width: 30px;
  height: 30px;
  background: radial-gradient(50% 50% at 50% 50%, #ffffff 0%, #e9eef5 100%);
  justify-items: center;
  display: flex;
  justify-content: center;
  border-radius: 50%;
}

.app-card .app-os img {
  width: 20px;
}

.app-status-container .inprogress-status {
  text-align: center;
  display: block;
}

.app-status-container .inprogress-status .spinner-icon {
  font-size: 100px;
}

.app-status-container .failed-status {
  margin: 0px auto;
  background-color: #d75a4a;
  color: white;
  width: 125px;
  height: 125px;
  text-align: center;
  font-size: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
}

.app-status-container .app-name {
  font-size: 30px;
  width: 85%;
  margin: 20px auto 20px;
  line-height: 36px;
  text-align: center;
}

.app-btns {
  position: static;
  z-index: 99;
}

.app-btns button {
  width: 100%;
  border: 1px solid #fff;
  padding: 0.7rem 0;
  border-radius: 0.4rem;
  cursor: pointer;
}

.details-btn {
  background: rgba(83, 114, 231, 0.1);
  color: #fff;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
}

.QRCode-btn {
  padding: 0.5rem 0 !important;
}

/* .new-app-link {
  background-color: #d3dce7;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: space-between;
  border-radius: 8px;
  padding: 10px 20px;
  margin: 20px 0px;
}

.new-app-link a,
.new-app-link .anticon {
  font-family: "Inter";
  font-weight: 400;
  font-size: 20px;
  color: #00244d;
}

.new-app-link .anticon {
  font-weight: bolder;
} */

@media (max-width: 991px) {
  .app-status-container .app-name {
    width: 100%;
  }

  .ant-col {
    min-width: 100%;
  }

  .new-app-link {
    width: 100%;
    text-align: center;
  }
}

/* End Application Component */

/* Start Models Components */

.details-model .model-image {
  width: 100px;
  height: 100px;
  background-color: #f5f7f9;
  border-radius: 8px;
}

.details-model .model-image img {
  width: 90%;
  height: 100%;
  object-fit: contain;
}

.details-model .ant-descriptions {
  background-color: #f5f7f9;
  padding: 1rem;
  border-radius: 8px;
}

.details-model .ant-descriptions .ant-descriptions-item-label {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #666c84;
}

.details-model .ant-descriptions .ant-descriptions-item-content {
  font-family: "Nunito";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 30px;
  color: #000000;
}

.details-model .ant-descriptions .copy-btn {
  background: rgba(83, 114, 231, 0.1);
  border: 1px solid #5372e7;
  border-radius: 7px;
  width: 140px;
  height: 50px;
  color: #5372e7;
  font-family: "Nunito";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
}

.details-model .ant-descriptions .copy-btn:active {
  background: rgba(83, 114, 231, 0.4);
}

.details-model .model-footer-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
  background: #5372e7;
  color: #fff;
  border: 0;
  border-radius: 8px;
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 14.5246px;
  line-height: 18px;
  cursor: pointer;
}

.QRcode-model .QRcode-model-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-top: 1rem;
}

.QRcode-model .QRcode-model-details h3 {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
}

.QRcode-model .QRcode-model-details p {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  text-align: center;
}

.QRcode-model .QRcode-model-code {
  border: 5px solid #5372e7;
  border-radius: 8px;
}

.QRcode-model .QRcode-model-btns {
  display: flex;
  align-items: center;
  gap: 1rem;
}

/* End Model Components */

/* Start Circle Components */
.circle {
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  border-radius: 50%;
  z-index: 0;
}

.circle-one {
  height: 300px;
  width: 300px;
  top: -8rem;
  left: -6rem;
}

.circle-two {
  height: 500px;
  width: 500px;
  top: -10rem;
  right: -6rem;
}

.circle-three {
  background: rgba(255, 255, 255, 0.1) !important;
  height: 250px;
  width: 250px;
  bottom: -10rem;
  right: -5rem;
}

/* End Circle Components */
