/* Start Table Component */
.overflow-x-auto {
    overflow-x: auto;
    background-color: white;
}

.overflow-x-auto::-webkit-scrollbar {
    height: 8px;
}

.overflow-x-auto::-webkit-scrollbar-thumb {
    border-radius: 8px;
}

.table {
    width: 100%;
}

.ant-table td.ant-table-cell{
    border-right: 1px solid rgb(0, 0, 0, 0.07);
}

.ant-table tr td.ant-table-cell:last-child{
    border-right: none;
}

.table thead {
    background: #F2F2F2;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    color: #000000;
    text-transform: uppercase;
}

.table thead th,
.table tbody td {
    padding: 10px 20px;
    border: 1px solid #B9B9B9;
}

.table tbody td {
    background: white;
    max-width: 200px;
    overflow-wrap: break-word;
}

.table tbody {
    min-height: 500px;
}

.ant-table .color-view {
    width: 100%;
    min-width: 150px;
    height: 35px;
    border-radius: 10px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.table .table-img-view {
    width: 150px;
}

.table-actions {
    font-size: 22px;
}

.table-actions >.anticon {
    cursor: pointer;
    margin: 0px 4px;
}

.table-actions .edit-icon {
    color: #00C28E;
}

.table-actions .delete-icon {
    color: #DD4947;
}

.table-actions .view-icon {
    color: #315B83;
    width: 19px;
    margin-top: 1px;
}

.main-delete-modal .main-delete-btn {
    margin-left: auto;
    display: block;
}

/* End Table Component */


/* Start Paginate Component */
.paginate {
    background-color: #F2F9FF;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    padding: 10px;
    display: flex;
    justify-content: right;
}

.paginate div {
    margin: 0px 15px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    color: #00244D;
}

.paginate .rows-page-trigger {
    color: #00244D;
    cursor: pointer;
}

.paginate .rows-page-trigger img {
    margin-left: 10px;
}

.paginate .page-control {
    cursor: pointer;
}

@media (max-width: 767px) {
    .paginate {
        display: block;
        text-align: center;
    }

    .page-control-container {
        display: inline-block;
    }
}

/* End Paginate Component */


/* Start DataGrid Component */
.datagrid {

    margin: 60px 20px 40px;
    font-family: Inter;
}

.datagrid .datagrid-heading {
    margin-left: 40px;
    margin-bottom: 0px;
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
}

.datagrid .table-container {
    background: rgba(255, 255, 255, 0.74);
    box-shadow: 0px 13.7303px 68.6514px rgba(0, 0, 0, 0.2);
    padding: 20px 20px 40px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    min-height: 450px;
}

.datagrid .btn-container {
    margin-bottom: 25px;
    display: flex;
    justify-content: space-between;
}

.datagrid .left-content {
    display: flex;
}

.datagrid .filter-button {
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 0px 2.9714px #0097C2;
    border: 0.990467px solid #0097C2;
    color: #25213B;
}

.datagrid .search-input {
    background: #F2F9FF;
    box-shadow: inset 0px 1.98093px 4.95233px rgba(0, 0, 0, 0.2);
    border-radius: 5.9428px;
    margin-left: 20px;
}

.datagrid .search-input input {
    background-color: #F2F9FF;
    margin: 0px 5px;
}

.datagrid .filter-button img {
    margin-right: 10px;
}

@media (max-width: 767px) {

    .datagrid .btn-container,
    .datagrid .left-content {
        display: block;
    }

    .datagrid .search-input {
        margin-left: 0px;
    }

    .datagrid .filter-button {
        width: 100%;
        margin: 10px 0px;
    }

    .datagrid .datagrid-heading {
        font-size: 20px;
        margin-left: 0px;
    }

    .datagrid .table-container {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
    }
}

@media (max-width: 991px) {
    .datagrid .table-container {
        overflow: auto;
    }
}

/* End DataGrid Component */


/* Start MainForm Component */
.main-form.col-2{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 16px;
}

.main-form .ant-form-item {
    margin-bottom: 10px;
}

.main-form .datepicker,
.main-form .ant-input-number {
    width: 100%;
}

.main-form textarea {
    min-height: 120px;
}

.main-form .color-picker {
    margin: 10px 0px;
}

.main-form .color-picker-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.main-form .color-picker-chooser {
    padding: 5px;
    height: 100%;
    background: #fff;
    border-radius: 1px;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1);
    display: inline-block;
    cursor: pointer;
    width: 100%;
}

.main-form .color-picker-chooser .selected-color {
    width: 100%;
    height: 100%;
    min-height: 15px;
    border-radius: 2px;
}

.main-form .color-picker-popover {
    position: absolute;
    display: none;
    z-index: 2;
}

.main-form .img-view {
    max-width: 250px;
    width: 100%;
    margin: 10px 0px;
}

/* End MainForm Component */


/* Start ObjectContainer Component */
.objects-container .obj-header {
    background-color: white;
    padding: 8px 10px;
}

.objects-container .obj-header img {
    margin: 0px 5px;
    cursor: pointer;
}

.objects-container .obj-header .search-input {
    margin-left: 5px;
    width: 75%;
}

.objects-container .obj-body {
    margin-top: 5px;
    max-height: 70vh;
    overflow-y: auto;
}

.objects-container .obj-body .obj-content:first-of-type {
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}

.objects-container .obj-body .obj-content:last-of-type {
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
}

.objects-container .obj-body .obj-content {
    background: #F6F9FC;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    border-bottom: 1px solid #E6EBF1;
}

.objects-container .obj-body .obj-content.selected {
    background-color: white;
}

.objects-container .obj-title {
    display: flex;
    width: 100%;
    padding: 0px 15px;
}

.objects-container .obj-title img {
    width: 28px;
    border-right: 1px solid #E6EBF1;
    padding-right: 12px;
}

.objects-container .selection-img {
    width: 22px;
    margin: 0px 10px;
}

.objects-container .obj-body .obj-content p {
    margin-bottom: 0px;
    font-family: Segoe UI;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #3C3C3F;
    padding: 10px;
}

.add-object-modal .ant-modal-content {
    border-radius: 5px;
    background-color: #F6F9FC;
}

.add-object-modal .ant-modal-close-x {
    height: 40px;
    line-height: 40px;
}

.add-object-modal .ant-modal-header {
    border-radius: 5px 5px 0 0;
    padding: 8px 24px;
}

.add-object-modal .ant-modal-body {
    padding: 16px 24px;
}

.add-object-modal .objform-content .objcontent-icon {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    height: 26px;
}

.add-object-modal .objform-content .objcontent-icon span {
    font-size: 22px;
    margin-right: 10px;
    background-color: #5B6DF5;
    color: white;
    width: 32px;
    display: inline-block;
    border-radius: 3px;
}

.add-object-modal .objcontent-border {
    padding-left: 26px;
    margin-left: 15px;
    border-left: 1px dotted #D1D7E2;
}

.add-object-modal .ant-input {
    border: 1px solid #fff;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 8%) 3px 2px 8px 0px;
}

.add-object-modal .ant-select {
    box-shadow: rgb(0 0 0 / 8%) 3px 2px 8px 0px;
}

.add-object-modal .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    border-radius: 3px;
    border: 1px solid #fff;
}

.add-object-modal ::-webkit-input-placeholder {
    color: #8e96ac;
}

.add-object-modal :-ms-input-placeholder {
    color: #8e96ac;
}

.add-object-modal ::placeholder {
    color: #8e96ac;
}

.add-object-modal .obj-name {
    min-height: 45px;
}

.add-object-modal .obj-button {
    margin: 20px auto 0px;
    display: block;
    background-color: #00244D;
    border: 1px solid #00244D;
    border-radius: 3px;
    width: 90px;
    font-size: 15px;
    height: 34px;
}

.add-object-modal .obj-actions {
    background-color: #8e96ac;
    color: #F6F9FC;
    border-radius: 50%;
    display: block;
    text-align: center;
    margin-top: 5px;
    cursor: pointer;
}

/* End ObjectContainer Component */

/* antd table styles */

.ant-table-cell{
    overflow-wrap: break-word;
    max-width: 250px;
}