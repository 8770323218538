@font-face {
    font-family: "Inter";
    src: url("./../../fonts/Inter-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Montserrat";
    src: url("./../../fonts/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Nunito";
    src: url("./../../fonts/Nunito-Regular.ttf") format("truetype");
}


* {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}

body.overflow-hidden {
    overflow: hidden;
}

#master {
    background-image: url('./../images/background.svg');
    background-size: cover;
    width: 100%;
    min-height: 100vh;
    position: absolute;
}

.ant-switch-checked {
    background-color: #10B817 !important;
}

.ant-switch {
    background-color: #FF0000;
}

.enterprise-alert-container {
    margin-top: 5rem;
}

.enterprise-alert {
    color: #333;
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    letter-spacing: -0.078px;
    color: #696969;
}

.align-center {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.main-scroll::-webkit-scrollbar {
    width: 5px;
}

.main-scroll::-webkit-scrollbar-thumb {
    background-color: rgba(0, 151, 194, 1);
}

.flex {
    display: flex;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-center {
    justify-content: center;
}

.spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 75vh;
}

.wd-100 {
    width: 100%;
}

.container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 768px) {
    .container {
        width: 750px;
    }
}

@media (min-width: 992px) {
    .container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    .container {
        width: 1170px;
    }
}