/* Start Configure App */
#config-app-layout .side-menu {
    background-color: #FFFFFF;
}

#config-app-layout .side-menu .menu {
    background-color: #FFFFFF;
}

#config-app-layout .side-menu .menu-item {
    border: 0;
    padding: 0 29px;
    height: 65px;
    line-height: 65px;
}

#config-app-layout .side-menu .menu-item img {
    width: 100%;
}

#config-app-layout .side-menu .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #ECF2FB;
}

#config-app-layout .side-menu .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected img {
    filter: invert(55%) sepia(212%) saturate(1062%) hue-rotate(192deg) brightness(100%) contrast(70%);
}

.generate-app {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.generate-app .gen-app-content {
    text-align: center;
}

.generate-app .gen-app-h {
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 49px;
    line-height: 67px;
    color: #285CC1;
    margin-bottom: 0px;
}

.generate-app .gen-app-p {
    background: #DDDEE2;
    font-family: 'Nunito';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #315B83;
    width: 45%;
    padding: 2px 0px;
    margin: 5px auto 15px;
}

.generate-app .st-button {
    display: block;
    margin: 15px auto 0px;
    background: #FD9D02;
    border: 1px solid #FD9D02;
    width: 140px;
    height: 40px;
    font-family: 'Nunito';
    border-radius: 7px;
}

.generate-app .gen-app-img {
    max-width: 100%;
}

.app-steps-container {
    background-color: #ECF2FB;
    height: 100vh;
    padding-top: 63px;
}

.app-steps-container .app-step {
    padding: 8px 32px 0px;
    height: 66px;
    border-bottom: 1px solid #E2EAF4;
}

.app-steps-container .app-step:last-of-type {
    border-bottom: none;
}

.app-steps-container .app-step p,
.app-steps-container .app-step h4 {
    font-family: 'Nunito';
    color: #D6E0ED;
    margin-bottom: 0px;
}

.app-steps-container .app-step p {
    font-size: 14px;
}

.app-steps-container .app-step h4 {
    font-size: 16px;
}

.app-steps-container .app-step.active p {
    color: #315B83;
}

.app-steps-container .app-step.active h4 {
    color: #0A2D5C;
}

.config-navbar .build-app-ref {
    background-color: #ECF2FB;
    color: #285CC1;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}

.config-navbar .build-app-ref a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.config-navbar .utube-icon {
    font-size: 18px;
    margin-right: 8px;
}

.config-navbar .profile-icons {
    font-size: 18px;
    color: #315B83;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100%;
}

.config-navbar .profile-icons span {
    cursor: pointer;
}

.config-navbar .profile-user {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
}

.config-layout {
    padding: 0px 0px;
    max-width: 100%;
}

.config-body {
    min-height: 100vh;
    background-color: white;
    padding: 10px 20px;
}

.config-content {
    padding: 15px 10px;
}

.mainform2 .ant-form-item-label>label {
    font-family: 'Nunito';
    font-size: 16px;
    color: #315B83;
}

.mainform2 .ant-input {
    background-color: #F6F8FA !important;
    height: 38px;
    border-radius: 5px;
    border: none;
}

.mainform2 .main-btn {
    background-color: #285CC1;
    border-radius: 6px;
    width: 150px;
    height: 38px;
    margin-top: 16px;
}

@media (max-width: 991px) {
    .generate-app {
        padding: 0px 15px;
    }

    #config-app-layout .side-menu .menu-item {
        padding: 0 30px;
        height: 60px;
        line-height: 60px;
    }

    .generate-app .gen-app-h {
        font-size: 30px;
        line-height: 30px;
    }

    .generate-app .gen-app-p {
        width: 100%;
        padding: 2px 0px;
        margin: 8px auto 15px;
    }

    .config-navbar .build-app-ref {
        background-color: white;
    }

    .config-navbar div {
        margin: 3px 0px;
    }

    .config-body {
        background-color: #ECF2FB;
    }
}

/* End Configure App */


/* Start General Settings */
.select-industry .industries-container {
    display: flex;
}

.select-industry .industry {
    width: 120px;
    text-align: center;
    cursor: pointer;
    margin-right: 32px;
}

.select-industry .industry img {
    background-color: #F6F8FA;
    width: 100%;
    padding: 26px 32px;
    border-radius: 6px;
}

.select-industry .industry .title,
.select-industry .industry-label {
    font-family: 'Nunito';
    font-size: 14px;
}

.select-industry .industry .title {
    color: #315B83;
    margin-top: 20px;
}

.select-industry .industry-label {

    color: #315B83;
    margin-bottom: 32px;
}

.select-template img {
    max-width: 100%;
    cursor: pointer;
}

.app-info img {
    max-width: 100%;
}

@media (max-width: 767px) {
    .select-industry .industries-container {
        display: block;
    }

    .select-industry .industry {
        margin: auto;
    }
}

/* End General Settings */