.Post__container {
    margin: 100px 32px;
}

.Post__container .ant-form-item{
    margin: 0 12px 0 0;
}

.Post__publish {
    margin: 0 0 24px;
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 30px;
    z-index: 100;
}

.Post__wrapper {
    padding: 24px;
    background-color: white;
    border-radius: 16px;
    box-shadow: 10px 10px 15px rgb(0, 0, 0, 0.1);
}

.Post__detail {
    background-color: white;
    border-radius: 16px;
    margin: 12px 0;
}

.Post__sub-detail {
    border: 1px solid hsl(0deg, 0%, 90%);
    padding: 16px;
    margin: 12px 0;
}

.Post__metadata {
    color: hsl(0deg, 0%, 40%);
    font-size: 14px;
    display: flex;
    gap: 24px;
    margin: 0 0 30px;
}

.Post__detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Post__sub-detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.Post__wrapper h2 {
    margin: 0 0 10px;
}

.grid-col-2{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
}

.Post__wrapper h3{
    margin: 0;
    font-size: 18px;
}