.icon-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}

.loader{
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* page styles */

.integration-list{
    padding: 100px 32px 50px;
}

.integration-list h2{
    font-size: 38px;
    margin: 0 0 42px;
}

.integration-list .tabs{
    display: inline-flex;
    padding: 4px;
    background: #fff;
    border-radius: 50px;
    margin: 0 0 32px;
}

.tabs .tab{
    width: 100px;
    padding: 4px;
    text-align: center;
    border-radius: 20px;
    cursor: pointer;
    text-transform: capitalize;
}

.tab.selected{
    background-color: #315B83;
    color: #fff;
}

.content{
    padding: 18px 32px;
    background-color: rgba(255, 255, 255, 0.74);
    box-shadow: 0px 13.7303px 68.6514px rgba(0, 0, 0, 0.2);
    border-radius: 18px;
}

.search-bar{
    display: flex;
    align-items: center;
    margin: 0 0 28px;
}

.search-bar .filter-btn{
    margin: 0 18px 0 0;
    background-color: #fff;
    border: 2px solid #0097C2;
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    padding: 16px;
    box-shadow: 0px 0px 2.9714px #0097C2;
}

.filter-btn img{
    margin: 0 12px 0 0;
}

.search-bar .search-input{
    background-color: #fff;
    min-width: 350px;
    border-radius: 5px;
    box-shadow: inset 0px 1.98093px 4.95233px rgba(0, 0, 0, 0.2);
    padding: 8px 12px;
    height: 40px;
    font-size: 16px;
    border: none;
}

.search-input:focus,
.search-input:active{
    outline: 2px solid #0097C2;
}

/* table styles */

.table .row{
    display: flex;
    margin: 0 0 16px;
    align-items: center;
}

.column{
    margin: 0 10px 0 0;
    text-align: center;
}

.box{
    padding: 4px;
    border-radius: 4px;
}

/* columns styles */

.enabled-status{
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #50B35A;
    border-radius: 50%;
}

.gray-box{
    background-color: #C6C6C6;
}

.white-box{
    background: rgba(255, 255, 255, 0.74);
    box-shadow: 0px 13.7303px 68.6514px rgba(0, 0, 0, 0.2);
}

.black-box{
    background-color: #454545;
    color: #38B795;
    text-align: left;
    padding: 4px 6px;
    border-radius: 4px;
}

.run-status-icon{
    width: 22px;
    height: 22px;
    background-color: #EED600;
    border-radius: 50%;
    margin: 0 auto;
}

.run-status img{
    width: 9px;
}

.run-status{
    background: rgba(255, 255, 255, 0.74);
    box-shadow: 0px 13.7303px 68.6514px rgba(0, 0, 0, 0.2);
    padding: 4px;
    border-radius: 4px
}

.run-status.succeeded{
    color: #00C28E;
}

.run-status.failed{
    color: #FF0000;
}

.play-icon{
    width: 25px;
    height: 25px;
    background-color: #00C28E;
    border-radius: 4px;
}

.run-details-btn{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 32px;
    color: #fff;
    background: #0097C2;
    border-radius: 4px;
}

.schedules{
    display: flex;
    justify-content: space-between;
}

.schedules .caret-icon{
    padding: 0 4px;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
}

.schedules .label{
    margin: 0 0 0 6px;
}

.pointer{
    cursor: pointer;
}

.close-btn{
    margin: 6px 0 0;
    display: inline-block;
    cursor: pointer;
}